// This is the type definition for my theme object.

export const theme = {
  button: {
    primary: "#f45511",
    primaryBtn: "#f45511",
    secondaryBtn: "green",
    connectBtnText: "#fff",
  },

  model: {
    connectModelbg: "#e5f1ea",
    connectModelbtnBgColor: "#fff",
    connectModelbtnTextColor: "#000",
    inputBgColor: "transparent",
    inputColor: "#fff",
  },
};

// const theme = light; // set the light theme as the default.
export default theme;
