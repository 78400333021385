import styled from "styled-components";

export const MainSection = styled.section`
  position: relative;
  overflow: hidden;
  overflow-x: hidden;
  min-height: calc(100vh - 80px);

  .video-div {
    position: absolute;
    inset: 0;
    object-fit: fill;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .text {
    text-align: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
  }

  .hidden {
    opacity: 0;
  }
`;
export const MainDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PlayBtn = styled.button`
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;
  background-color: #82fb01;
  font-size: 18px;
  border-radius: 0.25rem;
  color: #000;
  border: none;
  margin-bottom: 2rem;
  font-weight: 600;
  padding: 8px 2rem;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;

  &:disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;
