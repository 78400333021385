import styled, { css } from "styled-components";

import { Container, Row, Col } from "react-bootstrap";

export const MainContainer = styled(Container)``;

export const MainRow = styled(Row)``;

export const MainCol = styled(Col)``;

 
