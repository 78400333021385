import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { EffectCoverflow, Autoplay } from "swiper";
import {
  img10,
  img11,
  img12,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  bgImage,
} from "assets";
import { MainDiv, MainSection, PlayBtn } from "./elements";
import { CommonUtility } from "utility/common";
import { erc20Abi } from "utility/contract";
import environment from "environment";
import { ToastMessage } from "components/common";
import Loading from "components/common/loaders/loading";
import {
  AddUserRewardHook,
  GetProbabilityRatioHook,
  GetUserProfileHook,
} from "hooks/userHook";
import { Modal } from "antd";
import DisclaimerModal from "components/common/modal/disclaimerModal";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import Web3 from "web3";

const images = [
  {
    id: "0",
    src: img6,
    title: "Macbook Air",
    type: "physical",
    probability: 1,
  },
  { id: "1", src: img7, title: "1000 $RAWR", type: "digital", probability: 16 },
  {
    id: "2",
    src: img4,
    title: "Apple watch series 9",
    type: "physical",
    probability: 1,
  },
  {
    id: "3",
    src: img10,
    title: "5000 $RAWR",
    type: "digital",
    probability: 16,
  },
  { id: "4", src: img12, title: "X", type: "none", probability: 16 },
  { id: "5", src: img8, title: "2000 $RAWR", type: "digital", probability: 16 },
  {
    id: "6",
    src: img9,
    title: "10000 $RAWR",
    type: "digital",
    probability: 16,
  },
  {
    id: "7",
    src: img3,
    title: "$10 starbucks",
    type: "physical",
    probability: 1,
  },
  {
    id: "8",
    src: img11,
    title: "50000 $RAWR",
    type: "digital",
    probability: 16,
  },
  {
    id: "9",
    src: img5,
    title: "iPad 10th Gen",
    type: "physical",
    probability: 1,
  },
];



const HeroComp = ({reloadUserRawrBalance}) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { address, chainId } = useWeb3ModalAccount();

  const swiperRef = useRef(null);

  const [winner, setWinner] = useState(false);
  const [autoplayEnabled, setAutoplayEnabled] = useState(false);
  const [winnerPrize, setWinnerPrize] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { addUserReward } = AddUserRewardHook();
  const {
    getProbabilityRatio,
    data: probabilityData,
    loading,
  } = GetProbabilityRatioHook();
  const {
    getUserProfile,
    loading: userProfileLoading,
    error: userProfileError,
  } = GetUserProfileHook();

  const getReward = () => {
    const randNum = Math.random(); // generates a random number between 0 and 1
    let sum = 0;
    for (let i = 0; i < images.length; i++) {
      sum += images[i].probability / 100;
      if (randNum <= sum) {
        return i; // returns the index of the selected reward
      }
    }
  };

  const startAutoplay = async () => {
    setAutoplayEnabled(true);
    setWinner(false);
    if (swiperRef && swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.params.autoplay.delay = 0;
      swiperRef.current.swiper.params.speed = 50;
      swiperRef.current.swiper.autoplay.start();
    }
    const decelerate = () => {
      if (swiperRef && swiperRef.current && swiperRef.current.swiper) {
        let currentSpeed = swiperRef.current.swiper.params.speed;
        let newSpeed = currentSpeed + 50;
        if (newSpeed <= 1000) {
          swiperRef.current.swiper.params.speed = newSpeed;
          setTimeout(decelerate, 300);
        } else {
          setAutoplayEnabled(false);
          const activeIndex = getReward();
          const targetIndex = images.findIndex(
            (_, index) => index === activeIndex
          );

          const checkStop = () => {
            if (swiperRef.current.swiper.realIndex === targetIndex) {
              swiperRef.current.swiper.autoplay.stop();
              setWinner(true);
              setWinnerPrize(images[activeIndex].title);
              addUserReward({
                walletAddress: address,
                reward: images[activeIndex].title,
              });
              checkWinnerProfile(images[activeIndex].type);
            } else {
              setTimeout(checkStop, 100);
            }
          };
          checkStop();
        }
      }
    };
    setTimeout(decelerate, 5000);
  };

  const participate = async () => {
    try {
      setIsLoading(true);
      const web3 =  new Web3(walletProvider)
      const contract = CommonUtility.contract(
        web3,
        erc20Abi,
        environment.TOKEN_ADDRESS[chainId]
      );
      const userBalanceInWei = await contract.methods.balanceOf(address).call();
      const minRequiredBalance = environment.MIN_REQUIRED_BALANCE
      if (+userBalanceInWei < minRequiredBalance)
        throw "Insifficient tokens for participating";
      const txn = await contract.methods
        .transfer(environment.RECEIVER_ADDRESS[chainId], minRequiredBalance)
        .send({ from: address });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        await startAutoplay();
        await reloadUserRawrBalance()
        setIsLoading(false);
        ToastMessage("Success!", "Transaction successful", "success");
      }
    } catch (error) {
      console.log("pay fee function error", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };

  const checkWinnerProfile = async (rewardType) => {
    try {
      if (rewardType === "physical") {
        const userData = await getUserProfile(address);
        if (!userProfileLoading && !userData && !userProfileError) {
          showModal();
        }
      }
    } catch (error) {
      console.log("error in checkWinnerProfile func", error);
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    getProbabilityRatio();
  }, []);

  useEffect(() => {
    if (probabilityData && !loading) {
      images.forEach((image) => {
        const matchingData = probabilityData.find(
          (data) => data.id === image.id
        );
        if (matchingData) {
          image.probability = parseInt(matchingData.probability);
        }
      });
    }
  }, [probabilityData, loading]);

  return (
    <MainSection>
      <Modal open={open} centered onCancel={handleCancel}>
        <DisclaimerModal handleCancel={handleCancel} />
      </Modal>
      {isLoading && <Loading content={"Transfer in progress"} />}
      <div>
        <img className="video-div" src={bgImage}/>
        {/* <video className="video-div" src={bgvideo} autoPlay muted loop></video> */}
      </div>

      <MainDiv>
        <Swiper
          ref={swiperRef}
          effect={"coverflow"}
          autoplay={{
            delay: 0,
            enabled: autoplayEnabled,
          }}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2,
            slideShadows: true,
          }}
          navigation={false}
          initialSlide={5}
          pagination={false}
          loop={true}
          modules={[EffectCoverflow, Autoplay]}
          className="mySwiper"
        >
          {images.map((image, index) => (
            <SwiperSlide className="swiper-no-swiping" key={index}>
              <img src={image.src} alt={`Image ${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>

        {winner ? (
          <p className="text">Winner: {winnerPrize}</p>
        ) : (
          <p className="text hidden">Winner: {winnerPrize}</p>
        )}
        <PlayBtn
          onClick={() => {
            participate();
          }}
          disabled={!address}
        >
          Spin
        </PlayBtn>
      </MainDiv>
    </MainSection>
  );
};

export default HeroComp;
