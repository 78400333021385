import React, { useState, useEffect, useRef } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { MainSection, ContainerDiv } from "./elements";
import { connectbtn, logo, navLogo, usericon2 } from "assets";
import { CommonUtility } from "utility/common";
import { CreateUserHook } from "hooks/userHook";
import ProfileModal from "components/common/modal/profileModal";
import { Image } from "react-bootstrap";
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import environment from "environment";
import { AnimatePresence, motion } from "framer-motion";

const projectId = environment.WALLET_ID;

const chains = [
  {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    rpcUrl: "https://cloudflare-eth.com",
  },
];
const ethersConfig = defaultConfig({
  metadata: {
    name: "Web3Modal",
    description: "Web3Modal Laboratory",
    url: "https://web3modal.com",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
  },
  defaultChainId: 1,
  rpcUrl: "https://cloudflare-eth.com",
});

// 3. Create modal
createWeb3Modal({
  ethersConfig,
  chains,
  projectId,
  enableAnalytics: true,
  themeMode: "light",
  themeVariables: {
    "--w3m-color-mix": "#00DCFF",
    "--w3m-color-mix-strength": 20,
  },
});

function Navbar({userRawrBalance}) {
  const modal = useWeb3Modal();
  const { address } = useWeb3ModalAccount();
  const screenWidth = CommonUtility.getScreenWidth();
  console.log("CHAIN", address, screenWidth);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleModelOpen = () => {
    console.log("handleModelOpen");
    modal.open();
  };

  const { createUser } = CreateUserHook();

  useEffect(() => {
    if (address) {
      createUser({ walletAddress: address });
    }
  }, [address]);

  useEffect(() => {
    if (isCollapsed) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isCollapsed]);

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const modalRef = useRef(null);
  const showUserModal = () => {
    setIsUserModalOpen(!isUserModalOpen);
  };

  const handleCancel = () => {
    setIsUserModalOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleCancel();
    }
  };

  useEffect(() => {
    if (isUserModalOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isUserModalOpen]);

  return (
    <MainSection>
      <ContainerDiv>
        <div className="main-dev">
          <div className="first-div">
            <img
              src={navLogo}
              alt="nav-logo"
              className="img-div"
              onClick={() => window.open("https://pixelsaurus.io/ ")}
            />
            <div className="menus-div">
              <p
                onClick={() => window.open("https://pixelsaurus.io/")}
                className="menu-text"
              >
                PixelSaurus® Main Site
              </p>
              <p
                onClick={() =>
                  window.open("https://rawrcade.pixelsaurus.io/#games")
                }
                className="menu-text"
              >
                Games
              </p>
              <p
                onClick={() =>
                  window.open("https://rawrcade.pixelsaurus.io/#rawr")
                }
                className="menu-text"
              >
                $RAWR
              </p>
              <p className="menu-text">Leaderboard (Coming Soon)</p>
            </div>
          </div>

          <div className="button-div-wrapper">
            {address ? (
              <div className="profile-div">
                {
                  userRawrBalance &&
                  <div className="rawr-div"> {userRawrBalance} $RAWR </div>
                }
               
                <Image
                  src={usericon2}
                  onClick={showUserModal}
                  style={{ cursor: "pointer" }}
                  width={50}
                />
              </div>
            ) : (
              <img
                src={connectbtn}
                className="btn-div"
                onClick={handleModelOpen}
              />
            )}
          </div>

          <div className="hamburger-menu">
            {isCollapsed ? (
              <AiFillCloseCircle
                className="icons"
                onClick={() => setIsCollapsed(false)}
              />
            ) : (
              <GiHamburgerMenu
                className="icons"
                onClick={() => setIsCollapsed(true)}
              />
            )}
          </div>
        </div>

        <AnimatePresence mode="wait" initial={false}>
          {isCollapsed && (
            <motion.div
              className="mobile-main-dev"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.25 }}
            >
              <div className="inner-mobile-div">
                <p
                  onClick={() => {
                    window.open("https://pixelsaurus.io/");
                    setIsCollapsed(false);
                  }}
                  className="mobile-menu-text"
                >
                  PixelSaurus® Main Site
                </p>{" "}
                <p
                  onClick={() => {
                    window.open("https://rawrcade.pixelsaurus.io/#games");
                    setIsCollapsed(false);
                  }}
                  className="mobile-menu-text"
                >
                  Games
                </p>{" "}
                <p
                  onClick={() => {
                    window.open("https://rawrcade.pixelsaurus.io/#rawr");
                    setIsCollapsed(false);
                  }}
                  className="mobile-menu-text"
                >
                  $RAWR
                </p>{" "}
                <p className="mobile-menu-text">Leaderboard (Coming Soon)</p>
                {
                  userRawrBalance && 
                  <div className="rawr-div"> {userRawrBalance} $RAWR </div>
                }
                
                {address ? (
                  <div className="profile-div" style={{ marginTop: "1rem" }}>
                    <p className="menu-text1">
                      {CommonUtility.addressConvertor(address)}
                    </p>
                    <Image
                      src={usericon2}
                      onClick={showUserModal}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ) : (
                  <img
                    src={connectbtn}
                    className="mobile-menu-btn"
                    onClick={handleModelOpen}
                  />
                )}
                <AiFillCloseCircle
                  className="icons close-icon"
                  onClick={() => setIsCollapsed(false)}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {isUserModalOpen && (
          <div className="modal-div" ref={modalRef}>
            <ProfileModal onClose={handleCancel} />
          </div>
        )}
      </ContainerDiv>
    </MainSection>
  );
}

export default Navbar;
