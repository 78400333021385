import styled from "styled-components";

export const MainWrapper = styled.div`
  border-radius: 2.5rem;
  border: 1px solid #dee0da;
  background: rgba(130, 251, 1, 0);
  width: 34.0625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 576px) {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
    padding: 10px;
  }

  .data-policy-text {
    text-align: center;
    font-size: 14px;
    padding: 0 2.5rem;

    @media (max-width: 576px) {
      padding: 0 1.5rem;
    }
  }
`;
export const Title = styled.h5`
  color: #000;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1.5rem;
`;
export const InputDiv = styled.div`
  position: relative;
  .form-control {
    border-radius: 1.25rem;
    border: 1px solid #dee0da;
    background: #fff;
    width: 27.64613rem;
    height: 3.6875rem;
    padding: 0rem 1rem;

    @media (max-width: 576px) {
      width: 100%;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid #82fb01;
    }
  }

  .form-label {
    color: #c0c0c0;
    leading-trim: both;
    text-edge: cap;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .css-13cymwt-control {
    border-radius: 1.25rem;
    border: 1px solid #dee0da;
    background: #fff;
    width: 27.64613rem;
    height: 3.6875rem;
    padding: 0rem 1rem;
    margin-top: 1rem;
  }

  .css-t3ipsp-control {
    border-radius: 1.25rem;
    border: 1px solid #dee0da;
    background: #fff;
    width: 27.64613rem;
    height: 3.6875rem;
    padding: 0rem 1rem;
    margin-top: 1rem;
    box-shadow: none !important;
  }

  .css-t3ipsp-control:hover {
    border: 1px solid #82fb01;
  }

  .css-1fdsijx-ValueContainer {
    padding: 0px !important;
  }

  .css-1nmdiq5-menu {
    z-index: 3 !important;
  }
`;

export const AddressText = styled.p`
  margin-bottom: 0rem;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 1rem;
`;

export const SaveBtn = styled.button`
  color: #000;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 0.6875rem;
  background: #82fb01;
  width: 27.625rem;
  height: 2.9375rem;
  border: none;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  margin-bottom: 2rem;

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const MainSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  padding-bottom: 2rem;
`;
