// export { default as bgvideo } from "./videos/bgvideo.mp4";
export const Images = {
  web3: {
    metamask: require("./web3/metamask.png"),
    wallet: require("./web3/wallet.png"),
  },
  login: {
    bg: require("./images/bg-img.png"),
  },
};

export { default as img3 } from "./images/img3.png";
export { default as img4 } from "./images/img4.png";
export { default as img5 } from "./images/img5.png";
export { default as img6 } from "./images/img6.png";
export { default as img7 } from "./images/img7.png";
export { default as img8 } from "./images/img8.png";
export { default as img9 } from "./images/img9.png";
export { default as img10 } from "./images/img10.png";
export { default as img11 } from "./images/img11.png";
export { default as img12 } from "./images/img12.png";
export { default as logo } from "./images/logo.png";
export { default as usericon } from "./images/profileImg.png";
export { default as usericon2 } from "./images/profileImg.png";
export { default as bgImage } from "./images/newBackground.png";
export { default as navLogo } from "./images/navLogo.png";

export { default as connectbtn } from "./images/connectbtn.png";
export { default as modalbg } from "./images/modalbg.png";

export { default as bgvideo } from "./videos/bgvideo.mp4";
