import Navbar from "components/homeCom/topbar";
import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useMemo, useState } from "react";
import {
  AddressText,
  InputDiv,
  MainSection,
  MainWrapper,
  SaveBtn,
  Title,
} from "./elements";
import { FaSave } from "react-icons/fa";
import Select from "react-select";
import countryList from "react-select-country-list";
import { AddUserProfileHook, GetUserProfileHook } from "hooks/userHook";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { mainModel } from "store/redux/slices/helperSlices/modelSlice";
import { ToastMessage } from "components/common";
import { useAppDispatch } from "store/store";
import Loading from "components/common/loaders/loading";
import { useNavigate } from "react-router-dom";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";

const ProfileComp = () => {
  const navigate = useNavigate();
  const [buttonLabel, setButtonLabel] = useState("Save");
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useWeb3ModalAccount();

  const {
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      street: "",
      zipCode: "",
      city: "",
      country: "",
      discord: "",
      telegram: "",
      twitter: "",
    },
  });

  const dispatch = useAppDispatch();
  const handleModelOpen = () => {
    dispatch(mainModel(true));
  };
  useEffect(() => {
    if (!address) {
      navigate("/");
    }
  }, [address]);
  const { addUserProfile, error } = AddUserProfileHook();
  const {
    getUserProfile,
    data,
    loading,
    error: getUserError,
  } = GetUserProfileHook();
  const options = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    if (address) {
      getUserProfile(address);
    }
  }, [address]);

  useEffect(() => {
    if (!loading && !getUserError) {
      if (data) {
        setButtonLabel("Update");
        setValue("firstName", data.first_name);
        setValue("lastName", data.last_name);
        setValue("street", data.address.street);
        setValue("zipCode", data.address.zip_code);
        setValue("city", data.address.city);
        setValue("country", data.address.country);
        setValue("discord", data.social_accounts.discord);
        setValue("telegram", data.social_accounts.telegram);
        setValue("twitter", data.social_accounts.twitter);
      } else {
        setButtonLabel("Save");
      }
    }
  }, [data, loading]);

  const handleUserProfile = async (obj) => {
    try {
      setIsLoading(true);
      await addUserProfile(obj);
      if (error) ToastMessage("Error", error, "error");
      setIsLoading(false);
      const message = buttonLabel === "Save" ? "created" : "updated";
      ToastMessage("Success!", `Profile ${message} successfully`, "success");
    } catch (error) {
      console.log("error in handleUserProfile func", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      const obj = {
        walletAddress: address,
        profile: {
          firstName: data.firstName,
          lastName: data.lastName,
          address: {
            street: data.street,
            zip_code: data.zipCode,
            city: data.city,
            country: data.country,
          },
          socialAccounts: {
            discord: data.discord,
            telegram: data.telegram,
            twitter: data.twitter,
          },
        },
      };
      await handleUserProfile(obj);
      reset();
      navigate("/");
    } catch (error) {
      console.log("error in onSubmit func", error);
    }
  };

  return (
    <div>
      <Navbar />
      {isLoading && <Loading>Loading...</Loading>}
      <MainSection>
        <MainWrapper>
          <Title>Profile</Title>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputDiv className="mt-3">
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: "First name is required",
                  pattern: {
                    value: /^[A-Za-z]+$/i,
                    message: "First name should only contain letters",
                  },
                }}
                render={({ field }) => (
                  <FloatingLabel
                    controlId="floatingInput"
                    label="First Name"
                    type="text"
                    spellCheck={false}
                  >
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="First Name"
                    />
                  </FloatingLabel>
                )}
              />
              {errors.firstName && (
                <p
                  className="error"
                  style={{ color: "red", marginBottom: "0rem" }}
                >
                  {errors.firstName?.message}
                </p>
              )}
            </InputDiv>

            <InputDiv className="mt-3">
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: "Last name is required",
                  pattern: {
                    value: /^[A-Za-z]+$/i,
                    message: "Last name should only contain letters",
                  },
                }}
                render={({ field }) => (
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Last name (optional)"
                    type="text"
                  >
                    <Form.Control
                      {...field}
                      type="text"
                      placeholder="Last name (optional)"
                    />
                  </FloatingLabel>
                )}
              />
              {errors.lastName && (
                <p
                  className="error"
                  style={{ color: "red", marginBottom: "0rem" }}
                >
                  {errors.lastName?.message}
                </p>
              )}
            </InputDiv>

            <div>
              <AddressText>Address</AddressText>

              <InputDiv className="mt-2">
                <Controller
                  name="street"
                  control={control}
                  rules={{
                    required: "Street address is required",
                  }}
                  render={({ field }) => (
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Address"
                      type="text"
                    >
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Address"
                      />
                    </FloatingLabel>
                  )}
                />
                {errors.street && (
                  <p
                    className="error"
                    style={{ color: "red", marginBottom: "0rem" }}
                  >
                    {errors.street?.message}
                  </p>
                )}
              </InputDiv>
              <InputDiv className="mt-3">
                <Controller
                  name="zipCode"
                  control={control}
                  rules={{
                    required: "Zip code is required",
                    // pattern: {
                    //   value: /^[0-9]{5}(?:-[0-9]{4})?$/,
                    //   message: "Invalid zip code format",
                    // },
                  }}
                  render={({ field }) => (
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Zip Code"
                      type="text"
                    >
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Zip Code"
                      />
                    </FloatingLabel>
                  )}
                />
                {errors.zipCode && (
                  <p
                    className="error"
                    style={{ color: "red", marginBottom: "0rem" }}
                  >
                    {errors.zipCode?.message}
                  </p>
                )}
              </InputDiv>
              <InputDiv className="mt-3">
                <Controller
                  name="city"
                  control={control}
                  rules={{
                    required: "City is required",
                  }}
                  render={({ field }) => (
                    <FloatingLabel
                      controlId="floatingInput"
                      label="City"
                      type="text"
                    >
                      <Form.Control {...field} type="text" placeholder="City" />
                    </FloatingLabel>
                  )}
                />
                {errors.city && (
                  <p
                    className="error"
                    style={{ color: "red", marginBottom: "0rem" }}
                  >
                    {errors.city?.message}
                  </p>
                )}
              </InputDiv>

              <InputDiv>
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: "Country selection is required" }}
                  render={({ field }) => (
                    <Select {...field} options={options} />
                  )}
                />
                {errors.country && (
                  <p
                    className="error"
                    style={{ color: "red", marginBottom: "0rem" }}
                  >
                    {errors.country.message}
                  </p>
                )}
              </InputDiv>
            </div>

            <div>
              <AddressText>Social accounts: (optional)</AddressText>
              <InputDiv className="mt-2">
                <Controller
                  name="discord"
                  control={control}
                  defaultValue=""
                  rules={{
                    pattern: {
                      value: /^(?!.*?\.{2,})[a-z0-9_\.]{2,32}$/,
                      message: "Invalid Discord username",
                    },
                  }}
                  render={({ field }) => (
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Discord Username"
                      type="text"
                    >
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Discord Username"
                      />
                    </FloatingLabel>
                  )}
                />
                {errors.discord && (
                  <p
                    className="error"
                    style={{ color: "red", marginBottom: "0rem" }}
                  >
                    {errors.discord?.message}
                  </p>
                )}
              </InputDiv>

              <InputDiv className="mt-3">
                <Controller
                  name="telegram"
                  control={control}
                  defaultValue=""
                  rules={{
                    pattern: {
                      value: /([a-zA-Z0-9_-]*)\/?$/,
                      message: "Invalid Telegram username",
                    },
                  }}
                  render={({ field }) => (
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Telegram Username"
                      type="text"
                    >
                      <Form.Control
                        {...field}
                        type="text"
                        placeholder="Telegram Username"
                      />
                    </FloatingLabel>
                  )}
                />
                {errors.telegram && (
                  <p
                    className="error"
                    style={{ color: "red", marginBottom: "0rem" }}
                  >
                    {errors.telegram?.message}
                  </p>
                )}
              </InputDiv>

              <InputDiv className="mt-3">
                <Controller
                  name="twitter"
                  control={control}
                  defaultValue=""
                  rules={{
                    pattern: {
                      value: /^@?(\w{1,15})\b/,
                      message: "Invalid Twitter username",
                    },
                  }}
                  render={({ field }) => (
                    <FloatingLabel
                      controlId="floatingInput"
                      label="X"
                      type="text"
                    >
                      <Form.Control {...field} type="text" placeholder="X" />
                    </FloatingLabel>
                  )}
                />
                {errors.twitter && (
                  <p
                    className="error"
                    style={{ color: "red", marginBottom: "0rem" }}
                  >
                    {errors.twitter?.message}
                  </p>
                )}
              </InputDiv>
            </div>

            {address ? (
              <SaveBtn type="submit">
                <FaSave />
                {buttonLabel}
              </SaveBtn>
            ) : null}
          </form>
          {!address ? (
            <SaveBtn onClick={handleModelOpen}>
              <FaSave />
              Connect Wallet
            </SaveBtn>
          ) : null}

          <p className="data-policy-text">
            Data Policy: We do not use or share you data, except for sending out
            any prizes you may win.
          </p>
        </MainWrapper>
      </MainSection>
    </div>
  );
};

export default ProfileComp;
