import Navbar from "./topbar";
import HeroComp from "./hero";
import { CommonUtility } from "utility/common";
import { useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers5/react";
import Web3 from "web3";
import { erc20Abi } from "utility/contract";
import environment from "environment";
import { useEffect, useState } from "react";


const HomeCom = () => {
  const { walletProvider } = useWeb3ModalProvider();
  const { address, chainId } = useWeb3ModalAccount();

  const [userRawrBalance, setUserRawrBalance]= useState()

  const fetchUserBalance = async() => {
    try{
      const web3 =  new Web3(walletProvider)
      const contract = CommonUtility.contract(
        web3,
        erc20Abi,
        environment.TOKEN_ADDRESS[chainId]
      );
      const userBalanceInWei = await contract.methods.balanceOf(address).call();
      setUserRawrBalance(userBalanceInWei)
    }
    catch(error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    
    address && fetchUserBalance()
  },[address])
  return (
    <div>
      <Navbar userRawrBalance={userRawrBalance}/>
      <HeroComp reloadUserRawrBalance={fetchUserBalance}/>
    </div>
  );
};

export default HomeCom;
